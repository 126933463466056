import React, { useState, useEffect } from "react";

import banner_1 from "../../assets/images/landing/banner_1.png";
import banner_2 from "../../assets/images/landing/banner_2.jpg";
import banner_3 from "../../assets/images/landing/banner_3.jpg";
import banner_4 from "../../assets/images/landing/banner_4.jpg";
import cafe from "../../assets/images/landing/cafe.png";
import construction from "../../assets/images/landing/construction.jpg";
import fashion from "../../assets/images/landing/fashion.png";
import market from "../../assets/images/landing/market.png";
import motors from "../../assets/images/landing/motors.png";
import store from "../../assets/images/landing/store.jpg";
import farmhouse from "../../assets/images/landing/farmhouse.png";

import ConstructionIcon from "../../assets/images/icon/001-construction.png";
import SupermarketsIcon from "../../assets/images/icon/002-flower-shop.png";
import FoodIcon from "../../assets/images/icon/food 1.png";
import CafesIcon from "../../assets/images/icon/010-cafe.png";
import AppleIcon from "../../assets/images/icon/013-devices 1.png";
import AutomobileIcon from "../../assets/images/icon/007-car-dealer.png";
import FashionIcon from "../../assets/images/icon/005-online-store.png";
import DropshippingIocn from "../../assets/images/icon/delivery.png";

import AOS from "aos";
import Animation from "./Animation";
import "aos/dist/aos.css";

function Landing(props) {
  useEffect(() => {
    document.title = "MAG VENTURES";
    AOS.init({
      duration: 1000,
      easing: "ease",
    });
  }, []);

  return (
    <>
      <Animation />

      <div className="hero">
        <div id="banner" class="carousel slide carousel-fade" data-bs-pause="false" data-bs-ride="carousel" data-bs-interval="3000">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src={banner_1} className="d-md-block w-100 bannerImg" alt="..." />
            </div>
            <div class="carousel-item">
              <img src={banner_2} className="d-md-block w-100 bannerImg" alt="..." />
            </div>
            <div class="carousel-item">
              <img src={banner_3} className="d-md-block w-100 bannerImg" alt="..." />
            </div>
            <div class="carousel-item">
              <img src={banner_4} className="d-md-block w-100 bannerImg" alt="..." />
            </div>
          </div>
        </div>
        <div className="hero-content d-none">
          <div
            className="tagline"
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            Venture of Success
          </div>
          <h4
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            Welcome to
          </h4>
          <h2
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            MAG VENTURES
          </h2>

          <p
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            Where Investment Thrive and Capitals Multiply
          </p>
        </div>
      </div>
      <section
        className="simple-section"
        id="about"
        style={{ backgroundColor: "#f8f8f8" }}
      >
        <div className="container">
          <div
            className="section-title mb-4"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            ABOUT US
          </div>
          <p
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            Welcome to MAG VENTURES, Pakistan's leading venture of success. <br />
            From investing in thriving markets to introducing wonders and opportunities, Mag
            Ventures weaves a vibrant tapestry of experiences across Pakistan and beyond. Our
            portfolio includes markets that touch your everyday life such as construction,
            supermarkets, food chains, fashion and beauty products, and exclusive partnerships
            with Apple products. Our commitment to excellence drives our expansion into the
            vibrant markets of the Asia Region through our various projects like Mag Store, Mag
            Builders & Developers, Mag Supermarket, Mag’s Cafe, Mag Motors, which focuses on
            the automobile industry including the import and export of cars and auto parts and
            operating a car showroom. And Eleganz by Mag which brings the worlds of beauty and
            fashion together.
            We are also thrilled to introduce our newest venture- MAG Organic Meats which not
            only caters to the local market but also engages in the export and trade of high-quality,
            organic meat, ensuring the best standards from farm to table. As an umbrella entity,
            MAG VENTURES fosters growth, innovation, and quality service in every facet of our
            operations. We're not just a company; we're a network of opportunities. Through our
            affiliate programs, we invite you to join this exciting journey, to build a story together,
            brick by brick, bite by bite, and innovation by innovation.

          </p>
        </div>
      </section>
      <section className="simple-section">
        <div className="container position-relative">
          <div className="row">
            <div className="col-md-5">
              <div
                className="box-mv "
                data-aos="flip-left"
                data-aos-delay="300"
                data-aos-easing="ease-in-out"
              >
                <div className="section-title mb-4">MISSION</div>
                <p style={{ textAlign: "justify" }}>
                  At MAG VENTURES, our mission is to redefine industry standards
                  by delivering exceptional quality and value across all our
                  ventures. We strive to create enduring relationships with our
                  customers, employees, and stakeholders by consistently
                  exceeding expectations. Through sustainable practices,
                  innovation, and a customer-centric approach, we aim to be a
                  beacon of excellence in every market we serve, fostering
                  growth, and making a positive impact in the communities we
                  operate in. We strive to create spaces that inspire, nourish,
                  and connect.
                </p>
              </div>
            </div>
            <div className="col-md-5 offset-md-2">
              <div
                className="box-mv"
                data-aos="zoom-in"
                data-aos-delay="300"
                data-aos-easing="ease-in-out"
              >
                <div className="section-title mb-4">VISION</div>
                <p style={{ textAlign: "justify" }}>
                  Our vision at MAG VENTURES is to be a global leader in diverse
                  sectors, setting benchmarks for excellence and innovation. We
                  envision expanding our footprint across continents while
                  maintaining unwavering dedication to quality, integrity, and
                  customer satisfaction. By leveraging our expertise and
                  embracing emerging technologies, we aim to continuously
                  evolve, shaping the future of the industries we operate in,
                  and we dream of a world where every interaction with a Mag
                  product or service leaves a lasting impression.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="simple-section">
        <div className="container position-relative">
          <div className="row">
            <div className="col-md-8 offset-md-4">
              <img
                src={farmhouse}
                data-aos="zoom-in"
                data-aos-delay="300"
                data-aos-easing="ease-in-out"
                className="img-fluid"
              />
            </div>
          </div>
          <div
            className="box-content left"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            <div className="section-title mb-4">MAG Meat</div>
            <h4>Quality You Can Trust</h4>
            <p>
              Mag Organic Meats is our dedicated venture into the meat industry,
              ensuring high-quality and ethically sourced meat products for our
              consumers. Through partnerships with trusted contractors, we supply
              premium mutton and beef to vendors and slaughterhouses, maintaining the
              highest standards of hygiene and quality. Our focus extends beyond local
              markets, with a robust export strategy that positions Mag Organic Meats as
              a key player in the global meat industry.
              With a significant portion of the population consuming meat as a staple part
              of their diet, the demand for fresh and high-quality meat is ever-growing.
              This venture not only meets the local demand but through our export
              system we also cater to the international market, ensuring that our meat
              products are appreciated globally for their superior quality and taste.
            </p>
          </div>
        </div>
      </section>

      <section className="simple-section">
        <div className="container position-relative">
          <div className="row">
            <div className="col-md-8">
              <img
                src={store}
                data-aos="zoom-in"
                data-aos-delay="300"
                data-aos-easing="ease-in-out"
                className="img-fluid"
              />
            </div>
          </div>
          <div
            className="box-content right"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            <div className="section-title mb-4">MAG STORE</div>
            <h4>Unlock your Apple Adventure</h4>
            <p>
              Mag Store is an incredible project that combines the convenience
              of e-commerce with the excitement of physical stores. At Mag Store
              we are excited to offer a wide range of Apple Products. When it
              comes to Apple products, Mag Store can definitely have an
              affiliation with Apple or use drop shipping to offer their
              products. This means that Mag Store can partner with Apple as an
              authorized retailer. From the latest iPhones to MacBooks and
              iPads, we've got you covered. We're all about bringing the best
              tech to our customers. Currently, Mag Store is thriving online,
              but in 2024, we're taking it to the next level by introducing
              physical stores as well. So get ready to explore the best of both
              online and offline shopping experiences with Mag Store in 2024.
              {/* Here is the link of online Mag Store{" "}
              <a href="https://www.magstore.world" target="_blank">
                www.magstore.world
              </a> */}
            </p>
          </div>
        </div>
      </section>
      <section className="simple-section">
        <div className="container position-relative">
          <div className="row">
            <div className="col-md-8 offset-md-4">
              <img
                src={construction}
                data-aos="zoom-in"
                data-aos-delay="300"
                data-aos-easing="ease-in-out"
                className="img-fluid"
              />
            </div>
          </div>
          <div
            className="box-content left"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            <div className="section-title mb-4">MAG BUILDERS & DEVELOPERS</div>
            <h4>Build your Dreams</h4>
            <p>
              Mag Builders & Developers is all about building a solid foundation
              for the future. We specialize in creating exceptional structures
              that stand the test of time. From residential complexes to
              commercial buildings, our team of experts ensures top-notch
              quality and craftsmanship.
            </p>
          </div>
        </div>
      </section>
      <section className="simple-section">
        <div className="container position-relative">
          <div className="row">
            <div className="col-md-8">
              <img
                src={market}
                data-aos="zoom-in"
                data-aos-delay="300"
                data-aos-easing="ease-in-out"
                className="img-fluid"
              />
            </div>
          </div>
          <div
            className="box-content right"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            <div className="section-title mb-4">MAG SUPERMARKET</div>
            <h4>Your One-Stop Shop for All Your Needs</h4>
            <p>
              At Mag Super Market, we believe in providing customers with a
              seamless shopping experience. Our supermarkets offer a wide
              variety of fresh produce, groceries, and household items, all
              under one roof. With competitive prices and excellent customer
              service, we make shopping convenient and enjoyable.
            </p>
          </div>
        </div>
      </section>
      <section className="simple-section">
        <div className="container position-relative">
          <div className="row">
            <div className="col-md-8 offset-md-4">
              <img
                src={cafe}
                data-aos="zoom-in"
                data-aos-delay="300"
                data-aos-easing="ease-in-out"
                className="img-fluid"
              />
            </div>
          </div>
          <div
            className="box-content left"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            <div className="section-title mb-4">MAG'S CAFE </div>
            <h4>Taste the Magic</h4>
            <p>
              Mag's Cafe is a cozy and inviting space where you can relax and
              indulge in delicious food and beverages. From aromatic coffees to
              mouth watering pastries, our menu is designed to satisfy every
              craving. Whether you're meeting friends or enjoying some alone
              time, Mag's Cafe is the perfect spot to unwind.
            </p>
          </div>
        </div>
      </section>
      <section className="simple-section">
        <div className="container position-relative">
          <div className="row">
            <div className="col-md-8">
              <img
                src={motors}
                data-aos="zoom-in"
                data-aos-delay="300"
                data-aos-easing="ease-in-out"
                className="img-fluid"
              />
            </div>
          </div>
          <div
            className="box-content right"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            <div className="section-title mb-4">MAG MOTORS </div>
            <h4>Unleash Your Driving Passion</h4>
            <p>
              Mag Motors is not just about cars and auto parts; it's about the
              thrill of the open road. We offer a wide selection of high-quality
              vehicles, both new and pre-owned, along with top-notch service and
              maintenance. With Mag Motors, you can experience the joy of
              driving in style and comfort.
            </p>
          </div>
        </div>
      </section>
      <section className="simple-section">
        <div className="container position-relative">
          <div className="row">
            <div className="col-md-8 offset-md-4">
              <img
                src={fashion}
                data-aos="zoom-in"
                data-aos-delay="300"
                data-aos-easing="ease-in-out"
                className="img-fluid"
              />
            </div>
          </div>
          <div
            className="box-content left"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-easing="ease-in-out"
          >
            <div className="section-title mb-4">ELEGANZ BY MAG</div>
            <h4>Embrace Your Inner Elegance</h4>
            <p>
              Eleganz by Mag is where beauty and fashion collide, creating a
              world of endless possibilities. From trendy clothing to luxurious
              beauty products, we curate the latest trends to help you express
              your unique style. With Eleganz by Mag, you can embrace your inner
              fashionista and unleash your glam and elegance side.
            </p>
          </div>
        </div>
      </section>
      <section
        className="simple-section"
        style={{ backgroundColor: "#17181A" }}
      >
        <div className="container">
          <div className="section-title mb-4 text-white">Market Worth</div>
          <div className="row">
            <div
              className="col-md-3"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-easing="ease-in-out"
            >
              <div className="mw-box shadow-sm">
                <img src={ConstructionIcon} alt="" />
                <h3>Construction</h3>
                <p>
                  The global construction industry was estimated to be worth
                  around $11.4 trillion.
                </p>
              </div>
            </div>
            <div
              className="col-md-3"
              data-aos="zoom-in"
              data-aos-delay="400"
              data-aos-easing="ease-in-out"
            >
              <div className="mw-box shadow-sm">
                <img src={SupermarketsIcon} alt="" />
                <h3>Supermarkets</h3>
                <p>
                  The global supermarket and grocery store industry was valued
                  at approximately $7.4 trillion.
                </p>
              </div>
            </div>
            <div
              className="col-md-3"
              data-aos="zoom-in"
              data-aos-delay="500"
              data-aos-easing="ease-in-out"
            >
              <div className="mw-box shadow-sm">
                <img src={FoodIcon} alt="" />
                <h3>Food Chains</h3>
                <p>
                  The worth of the global fast food industry, which includes
                  major food chains, was estimated at over $570 billion.
                </p>
              </div>
            </div>
            <div
              className="col-md-3"
              data-aos="zoom-in"
              data-aos-delay="600"
              data-aos-easing="ease-in-out"
            >
              <div className="mw-box shadow-sm">
                <img src={CafesIcon} alt="" />
                <h3>Cafes</h3>
                <p>
                  The cafe industry's global worth was approximately $200
                  billion.
                </p>
              </div>
            </div>
            <div
              className="col-md-3"
              data-aos="zoom-in"
              data-aos-delay="700"
              data-aos-easing="ease-in-out"
            >
              <div className="mw-box shadow-sm">
                <img src={AppleIcon} alt="" />
                <h3>Apple Products</h3>
                <p>
                  Apple's market capitalization fluctuates, but it was around
                  $2.5 trillion, representing the value of the company and its
                  products
                </p>
              </div>
            </div>
            <div
              className="col-md-3"
              data-aos="zoom-in"
              data-aos-delay="800"
              data-aos-easing="ease-in-out"
            >
              <div className="mw-box shadow-sm">
                <img src={AutomobileIcon} alt="" />
                <h3>Meat Consumption</h3>
                <p>
                  The global market worth of meat, including mutton and beef, is estimated to
                  be around $1 trillion annually.
                </p>
              </div>
            </div>
            <div
              className="col-md-3"
              data-aos="zoom-in"
              data-aos-delay="900"
              data-aos-easing="ease-in-out"
            >
              <div className="mw-box shadow-sm">
                <img src={FashionIcon} alt="" />
                <h3>Fashion and Beauty Products</h3>
                <p>
                  The combined global value of the fashion and beauty industry
                  was estimated at over $2.5 trillion.
                </p>
              </div>
            </div>
            <div
              className="col-md-3"
              data-aos="zoom-in"
              data-aos-delay="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="mw-box shadow-sm">
                <img src={DropshippingIocn} alt="" />
                <h3>Dropshipping</h3>
                <p>
                  The global value of the Dropshipping business was estimated at
                  over $294.16 billion.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Landing;
